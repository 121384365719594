import styled from "@emotion/styled";

import IconBookmark from "./icon-bookmark.svg";
import IconBookmarked from "./icon-bookmarked.svg";

export const StyledIconBookmark = styled(IconBookmark)``;
export const StyledIconBookmarked = styled(IconBookmarked)``;

export const Container = styled.button`
  background-color: transparent;

  ${StyledIconBookmark},
  ${StyledIconBookmarked} {
    border-radius: 50%;

    cursor: pointer;

    :hover {
      background-color: #dddddd;
    }
  }
`;
