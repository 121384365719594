import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import type { IconSize } from "scmp-app/components/video/video-duration-overlay";

export const DurationContainer = styled.div`
  padding: 4px;

  border-radius: 0 4px 0 0;

  background: #ffffff;
`;

export const Duration = styled.span`
  color: #4585ff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;
`;

type IconProps = {
  $iconSize: IconSize;
};
export const Icon = styled.div<IconProps>`
  margin: 8px;

  & svg {
    display: ${({ $iconSize }) => ($iconSize === "hidden" ? "none" : "block")};

    inline-size: 36px;
    block-size: 36px;

    ${props => props.theme.breakpoints.up("tablet")} {
      inline-size: ${({ $iconSize }) => ($iconSize === "large" ? "48px" : "36px")};
      block-size: ${({ $iconSize }) => ($iconSize === "large" ? "48px" : "36px")};
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;

  display: grid;
  grid:
    "content" 1fr
    / 1fr;

  inline-size: 100%;
  block-size: 100%;

  ${Icon} {
    grid-area: content;

    place-self: start end;
  }

  ${DurationContainer} {
    grid-area: content;

    place-self: end start;
  }
`;
