import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;

  color: #000000;
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: normal;
  font-variant-numeric: lining-nums proportional-nums;

  strong {
    font-weight: 700;
  }
`;
