import type { BookmarkButtonAction } from "@product/scmp-sdk/dist/types/components/bookmark-button/types";
import type { FunctionComponent } from "react";

import { Container, StyledIconBookmark, StyledIconBookmarked } from "./styles";

type Props = {
  className?: string;
  isBookmarked?: boolean;
  onClick?: (
    action: BookmarkButtonAction,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

export const Article20Bookmark: FunctionComponent<Props> = ({
  className,
  isBookmarked,
  onClick,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(isBookmarked ? "unbookmark" : "bookmark", event);
  };

  return (
    <Container className={className} onClick={handleClick}>
      {isBookmarked ? <StyledIconBookmarked /> : <StyledIconBookmark />}
    </Container>
  );
};

Article20Bookmark.displayName = "Article20Bookmark";
