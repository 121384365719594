import styled from "@emotion/styled";

import PlayIcon from "./icon_play.svg";

export const Container = styled.div`
  position: absolute;
  inset-block-start: 8px;
  inset-inline-end: 8px;
`;

export const StyledPlayIcon = styled(PlayIcon)``;
