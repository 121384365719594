import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { checkHasLiveTag } from "scmp-app/components/article/article-render/helpers";
import type { contentLiveTagContent$key } from "scmp-app/queries/__generated__/contentLiveTagContent.graphql";

import { LiveTag } from "./styles";

type Props = {
  className?: string;
  dimLabelWhenLiveEnds?: boolean;
  hide?: boolean;
  reference: contentLiveTagContent$key;
};
export const ContentLiveTag: FunctionComponent<Props> = ({
  className,
  dimLabelWhenLiveEnds = false,
  hide = false,
  reference,
}) => {
  const content = useFragment(
    graphql`
      fragment contentLiveTagContent on Article {
        ...helpersCheckHasLiveTagArticle
      }
    `,
    reference,
  );

  const hasLiveTag = checkHasLiveTag(content);

  if (!hasLiveTag && !dimLabelWhenLiveEnds) {
    return null;
  }

  return (
    <LiveTag $dim={!hasLiveTag && dimLabelWhenLiveEnds} $hide={hide} className={className}>
      LIVE
    </LiveTag>
  );
};

ContentLiveTag.displayName = "ContentLiveTag";
