import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import CommentFilledIcon from "./icon-comment-filled.svg";
import CommentIcon from "./icon-comment.svg";

export const StyledCommentFilledIcon = styled(CommentFilledIcon)``;
export const StyledCommentIcon = styled(CommentIcon)``;

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  color: #4585ff;
  font-size: 12px;
  font-family: ${fontRoboto};

  ${StyledCommentFilledIcon}, ${StyledCommentIcon} {
    margin-inline-start: 6px;
  }
`;
