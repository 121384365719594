/**
 * @generated SignedSource<<1a9d21e596b5d11c6f2ea468f9c0b397>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentUpdatedDateContent$data = {
  readonly updatedDate: number | null | undefined;
  readonly " $fragmentType": "contentUpdatedDateContent";
};
export type contentUpdatedDateContent$key = {
  readonly " $data"?: contentUpdatedDateContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentUpdatedDateContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentUpdatedDateContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedDate",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "efc4ff769342d6d99d1e8fb1f64dc490";

export default node;
