import type { FunctionComponent } from "react";

import type { ContainerProps } from "./styles";
import { Container, Placeholder } from "./styles";

type Props = ContainerProps;

export const ContentPlaceholder: FunctionComponent<Props> = ({ ...props }) => (
  <Container {...props}>
    <Placeholder />
  </Container>
);

ContentPlaceholder.displayName = "ContentPlaceholder";
