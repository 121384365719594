/**
 * @generated SignedSource<<a571f586c36f25425bc769cbe98d31ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentPrintHeadlineContent$data = {
  readonly printHeadline: string | null | undefined;
  readonly " $fragmentType": "contentPrintHeadlineContent";
};
export type contentPrintHeadlineContent$key = {
  readonly " $data"?: contentPrintHeadlineContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentPrintHeadlineContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentPrintHeadlineContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printHeadline",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "72a52881623c73cb8ad69225c518f4e6";

export default node;
