export type BookmarkActions = "bookmark" | "unbookmark";

export enum BookmarkTypes {
  Node = "node",
}

export type BookmarkPayload = {
  action: BookmarkActions;
  id: string;
  type?: BookmarkTypes;
};

export type BookmarkActionResponse = {
  message: string;
};

export type GetBookmarksResponse = {
  userBookmarkList: UserBookmarkList;
};

export type UserBookmarkList = string[];

export interface BookmarkQueryStringPayload {
  action?: {
    bookmark?: string;
  };
}

export type BookmarkLocation = "header" | "others" | "side";
