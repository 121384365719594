/**
 * @generated SignedSource<<4cb5800f596442043f65dfbff9cced4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentTopicLinkContent$data = {
  readonly topics: ReadonlyArray<{
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "topicLinkTopic">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "contentTopicLinkContent";
};
export type contentTopicLinkContent$key = {
  readonly " $data"?: contentTopicLinkContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentTopicLinkContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentTopicLinkContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicLinkTopic"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "d43b97f5cfc71609be158f151fef4e2e";

export default node;
