import { css } from "@emotion/react";
import styled from "@emotion/styled";

export type FigureProps = {
  $aspectRatio: string;
  $aspectRatioDesktopUp: string;
  $aspectRatioTabletUp: string;
};

export const Figure = styled.figure<FigureProps>`
  inline-size: 100%;
  block-size: 100%;

  picture {
    ${props => css`
      aspect-ratio: ${props.$aspectRatio};

      ${props.theme.breakpoints.up("tablet")} {
        ${props.$aspectRatioTabletUp &&
        css`
          aspect-ratio: ${props.$aspectRatioTabletUp};
        `};
      }

      ${props.theme.breakpoints.up("desktop")} {
        ${props.$aspectRatioDesktopUp &&
        css`
          aspect-ratio: ${props.$aspectRatioDesktopUp};
        `};
      }
    `}
  }
`;

export const StyledFigcaption = styled.figcaption`
  margin-block-start: 8px;

  color: #999999;
  font-weight: 400;
  font-size: 12px;
`;
