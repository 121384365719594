import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { EntityLink } from "scmp-app/components/entity-link";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { CommentWidgetPosition } from "scmp-app/components/tracking/google-analytics-4/types";
import { commentHash } from "scmp-app/lib/hooks";
import type { articleCommentArticle$key } from "scmp-app/queries/__generated__/articleCommentArticle.graphql";

import { Container, StyledCommentFilledIcon, StyledCommentIcon } from "./styles";

type Props = {
  className?: string;
  highlightCommentThreshold?: number;
  reference: articleCommentArticle$key; // Number of comment to highlight this comment icon
  showCommentThreshold?: number; // Number of comment to show this comment icon
  trackingPosition: CommentWidgetPosition;
};

export const ArticleComment: FunctionComponent<Props> = ({
  className,
  highlightCommentThreshold,
  reference: reference_,
  showCommentThreshold,
  trackingPosition,
}) => {
  const article = useFragment(
    graphql`
      fragment articleCommentArticle on Article {
        ...entityLink
        commentCount
        entityId
      }
    `,
    reference_,
  );

  const commentCount = article.commentCount ?? 0;
  const highlightThreshold = highlightCommentThreshold ?? 30;
  const showThreshold = showCommentThreshold ?? 3;
  const isHighlighted = commentCount > highlightThreshold;
  const isShow = commentCount > showThreshold;

  const handleGA4Tracking = () => {
    sendGA4Tracking({
      action: "click",
      category: "comment",
      customized_parameters: {
        article_id: article.entityId,
        position: trackingPosition,
      },
      subcategory: "widget",
    });
  };

  return (
    <>
      {isShow ? (
        <EntityLink hash={commentHash} onClick={handleGA4Tracking} reference={article}>
          <Container className={className}>
            <span>{article.commentCount}</span>
            {isHighlighted ? (
              <StyledCommentFilledIcon height={15} width={15} />
            ) : (
              <StyledCommentIcon height={15} width={15} />
            )}
          </Container>
        </EntityLink>
      ) : null}
    </>
  );
};

ArticleComment.displayName = "ArticleComment";
