/**
 * @generated SignedSource<<9567d1581c7e3ae2551e70f536a4c37f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentAuthorLinkContent$data = {
  readonly authors: ReadonlyArray<{
    readonly entityId: string;
    readonly entityUuid: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"hooksContentHelperContent">;
  readonly " $fragmentType": "contentAuthorLinkContent";
};
export type contentAuthorLinkContent$key = {
  readonly " $data"?: contentAuthorLinkContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentAuthorLinkContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentAuthorLinkContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityUuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksContentHelperContent",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSanitizeArticleTypeEntityIdsArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleTypeSegment",
                  "kind": "LinkedField",
                  "name": "types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleType",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSanitizeAuthorTypesArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authors",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "types",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSanitizedAuthorEntityIdsArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authors",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flag",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "06cc338155bf730d1cb0fdc4cae3dc3e";

export default node;
