import _extends from "/app/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_ldtjt6rkmkjh5d5oa7uchoeul4/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_ldtjt6rkmkjh5d5oa7uchoeul4/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["componentTag", "isTextOverflowEllipsis", "reference"];
var _contentSummaryContent;
var __jsx = React.createElement;
import React from "react";
import { useFragment } from "react-relay";
import { H2Container, H3Container } from "./styles";
export const ContentSummary = _ref => {
  var _content$summary, _content$summary2;
  let {
      componentTag = "h2",
      isTextOverflowEllipsis,
      reference: reference_
    } = _ref,
    attributes = _objectWithoutProperties(_ref, _excluded);
  const content = useFragment(_contentSummaryContent !== void 0 ? _contentSummaryContent : _contentSummaryContent = require("../../../queries/__generated__/contentSummaryContent.graphql"), reference_);
  const isVideo = (content === null || content === void 0 ? void 0 : content.__typename) === "Video";
  const isEnableTextOverflowEllipsis = (isTextOverflowEllipsis && isVideo) ?? false;
  if (!((_content$summary = content.summary) !== null && _content$summary !== void 0 && (_content$summary = _content$summary.text) !== null && _content$summary !== void 0 && _content$summary.trim())) return null;
  const ContainerWithTag = componentTag === "h2" ? H2Container : H3Container;
  return __jsx(ContainerWithTag, _extends({
    $isEnableTextOverflowEllipsis: isEnableTextOverflowEllipsis
  }, attributes, {
    "data-qa": "ContentSummary-ContainerWithTag"
  }), ((_content$summary2 = content.summary) === null || _content$summary2 === void 0 ? void 0 : _content$summary2.text) ?? "");
};
ContentSummary.displayName = "ContentSummary";