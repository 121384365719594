import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { checkIfNewsSectionInFirstIndex } from "scmp-app/lib/section";
import type { contentSectionsContent$key } from "scmp-app/queries/__generated__/contentSectionsContent.graphql";

import { SectionContainer, SectionLink } from "./styles";

export type Props = {
  className?: string;
  hide?: boolean;
  isDisableLink?: boolean;
  onClick?: (sectionName: string) => void;
  reference: contentSectionsContent$key;
};

export const ContentSections: FunctionComponent<Props> = ({
  className,
  hide = false,
  isDisableLink,
  onClick,
  reference,
}) => {
  const { sections } = useFragment(
    graphql`
      fragment contentSectionsContent on Content {
        sections {
          value {
            ...helpersCheckIfNewsSectionInFirstIndexSection
            ...entityLink
            name
          }
        }
      }
    `,
    reference,
  );

  const firstSection = useMemo(() => first(sections)?.value ?? [], [sections]);
  const sectionsForDisplay = useMemo(
    () =>
      firstSection
        .slice(-1)
        .filter((section, index) => !checkIfNewsSectionInFirstIndex(section, index)),
    [firstSection],
  );

  const render = () =>
    sectionsForDisplay.map((section, index) => {
      if (section) {
        return isDisableLink ? (
          <span key={index}>{section?.name}</span>
        ) : (
          <SectionLink key={index} onClick={() => onClick?.(section.name)} reference={section}>
            <span>{section?.name}</span>
          </SectionLink>
        );
      }
    });

  if (sectionsForDisplay.length === 0) return null;

  return (
    <SectionContainer $hide={hide} className={className}>
      {render()}
    </SectionContainer>
  );
};

ContentSections.displayName = "ContentSections";
