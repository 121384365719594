import { useCallback, useEffect, useState } from "react";

// This hooks will return a wrapper function to execute the input function when all the awaitingStates is ready
export const useDeferredAction = (awaitingStates: boolean[]) => {
  const isAllAwaitingStatesReady = awaitingStates.every(state => state === true);
  // eslint-disable-next-line react/hook-use-state
  const [, setPendingActions] = useState<(() => void)[]>([]);

  useEffect(() => {
    if (!isAllAwaitingStatesReady) return;
    setPendingActions(pendingActions => {
      pendingActions.every(pendingAction => pendingAction());
      return [];
    });
  }, [isAllAwaitingStatesReady]);

  const deferRun = useCallback(
    (action: () => void) => {
      if (isAllAwaitingStatesReady) {
        action();
      } else {
        setPendingActions(pendingActions => [...pendingActions, action]);
      }
    },
    [isAllAwaitingStatesReady],
  );

  return { deferRun };
};
