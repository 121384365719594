import { atom } from "jotai";

export type Bookmark = {
  showNewBookmarkAnimation: boolean;
  showRippleEffect: boolean;
  showSmallRipple: boolean;
  toggleBookmarkRippleEffect: (smallRipple?: boolean) => void;
  toggleShowNewBookmarkAnimation: (state: boolean) => void;
};

export const bookmarkAtom = atom<Bookmark>({
  showNewBookmarkAnimation: false,
  showRippleEffect: false,
  showSmallRipple: false,
  toggleBookmarkRippleEffect: () => {},
  toggleShowNewBookmarkAnimation: () => {},
});
