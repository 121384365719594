import classnames from "classnames";
import type { FunctionComponent, ReactNode } from "react";

import { IconStyled } from "./styles";

export type Props = {
  children?: ReactNode;
  className?: string;
  color?: string;
  size?: string;
};

export const Icon: FunctionComponent<Props> = ({
  children,
  className,
  color = "inherit",
  size = "24px",
}) => (
  <IconStyled $color={color} $size={size} className={classnames("material-icons", className)}>
    {children}
  </IconStyled>
);

Icon.displayName = "Icon";
