import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { formatInTimeZone } from "scmp-app/lib/utils";
import type { contentUpdatedDateContent$key } from "scmp-app/queries/__generated__/contentUpdatedDateContent.graphql";

import { Date } from "./styles";

type Props = {
  className?: string;
  format?: string;
  reference: contentUpdatedDateContent$key;
};

export const ContentUpdatedDate: FunctionComponent<Props> = ({
  className,
  format = "dd MMM, yyyy",
  reference: reference_,
}) => {
  const { updatedDate } = useFragment(
    graphql`
      fragment contentUpdatedDateContent on Content {
        updatedDate
      }
    `,
    reference_,
  );

  return updatedDate ? (
    <Date className={className}>{formatInTimeZone(updatedDate, format)}</Date>
  ) : null;
};

ContentUpdatedDate.displayName = "ContentUpdatedDate";
