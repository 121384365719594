import type { FunctionComponent } from "react";

import type { StyledProps } from "./styles";
import { Container, Duration, Icon } from "./styles";

type Props = {
  className?: string;
  duration: string;
} & PropsFromStyledProps<StyledProps>;

export const DefaultVideoDurationOverlay: FunctionComponent<Props> = ({
  className,
  duration,
  responsiveVariants,
}) => (
  <Container $responsiveVariants={responsiveVariants} className={className}>
    <Icon /> <Duration>{duration}</Duration>
  </Container>
);
