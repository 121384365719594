import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { formatInTimeZone } from "scmp-app/lib/utils";
import type { contentPublishedDateContent$key } from "scmp-app/queries/__generated__/contentPublishedDateContent.graphql";

import { Date } from "./styles";

type Props = {
  className?: string;
  format?: string;
  reference: contentPublishedDateContent$key;
};

export const ContentPublishedDate: FunctionComponent<Props> = ({
  className,
  format = "dd MMM, yyyy",
  reference: reference_,
}) => {
  const { publishedDate } = useFragment(
    graphql`
      fragment contentPublishedDateContent on Content {
        publishedDate
      }
    `,
    reference_,
  );

  return publishedDate ? (
    <Date className={className}>{formatInTimeZone(publishedDate, format)}</Date>
  ) : null;
};

ContentPublishedDate.displayName = "ContentPublishedDate";
