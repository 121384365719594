import styled from "@emotion/styled";

export const Container = styled.div``;

export const StyledMessage = styled.span`
  color: #a1a1a1;
`;

export const StyledHeadline = styled.span`
  color: #666666;
`;
