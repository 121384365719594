import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { isSCMPColumnist, isSCMPEditorial, isSCMPPR } from "scmp-app/components/author/helpers";
import type { Props as BaseImageProps } from "scmp-app/components/common/base-image";
import { useImageUtils } from "scmp-app/lib/hooks";
import type { authorAvatarAuthor$key } from "scmp-app/queries/__generated__/authorAvatarAuthor.graphql";

import { Figure, StyledEntityLink, StyledImage, StyledPlaceholder } from "./styles";

export type Props = {
  className?: string;
  height: number;
  onClick?: (entityUuid: string) => void;
  placeholder?: boolean;
  reference: authorAvatarAuthor$key;
  width: number;
  withOutline?: boolean;
  withoutLink?: boolean;
} & Pick<BaseImageProps, "disableLinkingData">;

export const AuthorAvatar: FunctionComponent<Props> = ({
  className,
  disableLinkingData,
  height,
  onClick,
  placeholder,
  reference: reference_,
  width,
  withOutline,
  withoutLink,
  ...attribs
}) => {
  const author = useFragment(
    graphql`
      fragment authorAvatarAuthor on Author {
        ...entityLink
        entityId
        entityUuid
        name
        types
        image(filter: { type: AUTHOR_IMAGE }) {
          style(filter: { style: "300x300" }) {
            url
          }
        }
      }
    `,
    reference_,
  );
  const { getResizedImageUrl } = useImageUtils();
  if (!author.image?.style?.url && !placeholder) return null;

  // Author is scmp editorial or scmp pr should be in color
  const isImageInColor = isSCMPEditorial([author.entityId]) || isSCMPPR([author.entityId]);
  const withBorderColor = isSCMPEditorial([author.entityId]) || isSCMPColumnist(author.types);

  const renderImage = () =>
    author.image?.style?.url ? (
      <StyledImage
        $aspectRatio={`${width / height}`}
        $height={`${height}px`}
        $width={`${width}px`}
        alt={author.name}
        disableLinkingData={disableLinkingData}
        onClick={() => onClick?.(author.entityUuid)}
        src={getResizedImageUrl(author.image?.style?.url, width)}
      />
    ) : (
      <StyledPlaceholder />
    );

  return (
    <Figure
      $isColored={isImageInColor}
      $withBorderColor={withBorderColor}
      $withOutline={withOutline}
      className={className}
    >
      {withoutLink ? (
        renderImage()
      ) : (
        <StyledEntityLink reference={author} {...attribs}>
          {renderImage()}
        </StyledEntityLink>
      )}
    </Figure>
  );
};

AuthorAvatar.displayName = "AuthorAvatar";
