import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { Spacer } from "scmp-app/components/common/spacer";
import type { CommentWidgetPosition } from "scmp-app/components/tracking/google-analytics-4/types";
import { formatDistanceToNow, toISOString } from "scmp-app/lib/utils";
import type { contentActionBarContent$key } from "scmp-app/queries/__generated__/contentActionBarContent.graphql";

import type { StyledArticleCommentProps } from "./styles";
import {
  FlexContainer,
  IconContainer,
  StyledArticleComment,
  StyledContentBookmark,
  VideoIcon,
} from "./styles";

export type Props = {
  className?: string;
  commentVariant?: StyledArticleCommentProps["$variant"];
  displayDateVariant?: "publishedDate" | "updatedDate";
  isBookmarkVisible?: boolean;
  isCommentsVisible?: boolean;
  isIconVisible?: boolean;
  reference: contentActionBarContent$key;
  trackingPosition?: CommentWidgetPosition;
};

export const ContentActionBar: FunctionComponent<Props> = ({
  className,
  commentVariant = "default",
  displayDateVariant = "updatedDate",
  isBookmarkVisible = true,
  isCommentsVisible = true,
  isIconVisible = true,
  reference: reference_,
  trackingPosition = "rail",
}) => {
  const content = useFragment(
    graphql`
      fragment contentActionBarContent on Content {
        entityId
        createdDate
        updatedDate
        publishedDate
        ...articleCommentArticle
        ... on Article {
          hasVideoContent
        }
      }
    `,
    reference_,
  );

  const handleRenderDisplayDateTime = () => {
    const displayDateTime =
      displayDateVariant === "publishedDate"
        ? (content.publishedDate ?? content.createdDate)
        : content.updatedDate;
    if (!displayDateTime) return null;
    return (
      <time dateTime={toISOString(displayDateTime)} suppressHydrationWarning>
        {formatDistanceToNow(displayDateTime)}
      </time>
    );
  };

  return (
    <FlexContainer className={className}>
      {handleRenderDisplayDateTime()}
      {/*  Cspell:ignore videocam */}
      {isIconVisible && content.hasVideoContent && (
        <VideoIcon color="#d7d7d7" size="20px">
          videocam
        </VideoIcon>
      )}
      <Spacer />
      <IconContainer>
        {isCommentsVisible && (
          <StyledArticleComment
            $variant={commentVariant}
            reference={content}
            trackingPosition={trackingPosition}
          />
        )}

        {isBookmarkVisible && (
          <StyledContentBookmark
            entityId={content.entityId}
            ga4Events={{
              click: {
                action: "click",
                category: "bookmark",
                customized_parameters: {
                  action_type: "add",
                  article_id: content.entityId,
                  trigger_point: "article_overlay",
                },
                subcategory: "",
              },
            }}
          />
        )}
      </IconContainer>
    </FlexContainer>
  );
};

ContentActionBar.displayName = "ContentActionBar";
