import styled from "@emotion/styled";

type Props = {
  $color: string;
  $size: string;
};
export const IconStyled = styled.span<Props>`
  color: ${props => props.$color};
  size: ${props => props.$size};

  /* Prevent the priority is lower than the class material-icons in dev */
  font-size: ${props => props.$size} !important;
`;
