import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseImage } from "scmp-app/components/common/base-image";
import { useResponsiveImage } from "scmp-app/components/common/base-image/hooks";
import { ContentPlaceholder } from "scmp-app/components/content/content-placeholder";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import type { contentCoverImageContent$key } from "scmp-app/queries/__generated__/contentCoverImageContent.graphql";

import { Figure, StyledFigcaption } from "./styles";

type Props = {
  className?: string;
  reference: contentCoverImageContent$key;
  responsiveVariants: ResponsiveVariants<
    | "landscape250x99"
    | "size80x80"
    | "size320x180"
    | "size320x240"
    | "size500x500"
    | "size540x360"
    | "size640x360"
    | "size660w"
    | "size768x768"
    | "size800x1200"
    | "size1200x800"
    | "size1200x1200"
    | "size1280x720"
  >;
  withCaption?: boolean;
};

export const ContentCoverImage: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  responsiveVariants,
  withCaption,
}) => {
  const content = useFragment(
    graphql`
      fragment contentCoverImageContent on Content
      @argumentDefinitions(
        withLandscape250x99: { type: "Boolean", defaultValue: false }
        withSize1200x800: { type: "Boolean", defaultValue: false }
        withSize1200x1200: { type: "Boolean", defaultValue: false }
        withSize1280x720: { type: "Boolean", defaultValue: false }
        withSize640x360: { type: "Boolean", defaultValue: false }
        withSize768x768: { type: "Boolean", defaultValue: false }
        withSize540x360: { type: "Boolean", defaultValue: false }
        withSize320x240: { type: "Boolean", defaultValue: false }
        withSize320x180: { type: "Boolean", defaultValue: false }
        withSize80x80: { type: "Boolean", defaultValue: false }
        withSize500x500: { type: "Boolean", defaultValue: false }
        withSize800x1200: { type: "Boolean", defaultValue: false }
        withSize660w: { type: "Boolean", defaultValue: false }
      ) {
        images {
          url
          title
          type
          landscape250x99: style(filter: { style: "landscape_250_99" })
            @include(if: $withLandscape250x99) {
            url
          }
          size1200x800: style(filter: { style: "1200x800" }) @include(if: $withSize1200x800) {
            url
          }
          size1200x1200: style(filter: { style: "1200x1200" }) @include(if: $withSize1200x1200) {
            url
          }
          size1280x720: style(filter: { style: "wide_landscape" }) @include(if: $withSize1280x720) {
            url
          }
          size640x360: style(filter: { style: "wide_landscape_640_360" })
            @include(if: $withSize640x360) {
            url
          }
          size768x768: style(filter: { style: "768x768" }) @include(if: $withSize768x768) {
            url
          }
          size540x360: style(filter: { style: "540x360" }) @include(if: $withSize540x360) {
            url
          }
          size320x240: style(filter: { style: "320x240" }) @include(if: $withSize320x240) {
            url
          }
          size320x180: style(filter: { style: "320x180" }) @include(if: $withSize320x180) {
            url
          }
          size80x80: style(filter: { style: "80x80" }) @include(if: $withSize80x80) {
            url
          }
          size500x500: style(filter: { style: "500x500" }) @include(if: $withSize500x500) {
            url
          }
          size800x1200: style(filter: { style: "portrait" }) @include(if: $withSize800x1200) {
            url
          }
          size660w: style(filter: { style: "660w" }) @include(if: $withSize660w) {
            url
          }
        }
      }
    `,
    reference_,
  );

  const mainImage =
    content.images?.find(image => image?.type === "mainImage") ?? first(content.images);

  const variantSpecs = {
    landscape250x99: { aspectRatio: "250/99", url: mainImage?.landscape250x99?.url, width: 2000 },
    size80x80: { aspectRatio: "1/1", url: mainImage?.size80x80?.url, width: 80 },
    size320x180: { aspectRatio: "4/3", url: mainImage?.size320x180?.url, width: 320 },
    size320x240: { aspectRatio: "4/3", url: mainImage?.size320x240?.url, width: 320 },
    size500x500: { aspectRatio: "1/1", url: mainImage?.size500x500?.url, width: 500 },
    size540x360: { aspectRatio: "3/2", url: mainImage?.size540x360?.url, width: 540 },
    size640x360: { aspectRatio: "16/9", url: mainImage?.size640x360?.url, width: 640 },
    size660w: { aspectRatio: "260/183", url: mainImage?.size660w?.url, width: 660 },
    size768x768: { aspectRatio: "1/1", url: mainImage?.size768x768?.url, width: 768 },
    size800x1200: { aspectRatio: "2/3", url: mainImage?.size800x1200?.url, width: 800 },
    size1200x800: { aspectRatio: "3/2", url: mainImage?.size1200x800?.url, width: 1200 },
    size1200x1200: { aspectRatio: "1/1", url: mainImage?.size1200x1200?.url, width: 1200 },
    size1280x720: { aspectRatio: "16/9", url: mainImage?.size1280x720?.url, width: 1280 },
  };

  const mapVariant = (viewportSize: "desktopUp" | "mobileUp" | "tabletUp") => ({
    aspectRatio: variantSpecs[responsiveVariants[viewportSize]].aspectRatio,
    url: variantSpecs[responsiveVariants[viewportSize]].url ?? "",
    width: variantSpecs[responsiveVariants[viewportSize]].width,
  });

  const responsiveVariant = {
    desktopUp: mapVariant("desktopUp"),
    mobileUp: mapVariant("mobileUp"),
    tabletUp: mapVariant("tabletUp"),
  };

  const { bindResponsiveAspectRatio } = useResponsiveImage(responsiveVariant);

  return (
    <Figure {...bindResponsiveAspectRatio} className={className}>
      {mainImage?.url ? (
        <BaseImage
          $objectFit="cover"
          alt={mainImage?.title ?? undefined}
          responsiveVariant={responsiveVariant}
          src={mainImage?.url}
          {...bindResponsiveAspectRatio}
          lazyload
        />
      ) : (
        <ContentPlaceholder {...bindResponsiveAspectRatio} />
      )}

      {withCaption && mainImage?.title && <StyledFigcaption>{mainImage?.title}</StyledFigcaption>}
    </Figure>
  );
};

ContentCoverImage.displayName = "ContentCoverImage";
