/**
 * @generated SignedSource<<ce430b21d8dfe1eb844d27ff66bc7939>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentTopicLinkSuperTopic$data = {
  readonly relatedLink: ReadonlyArray<{
    readonly url: string;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"topicCheckSuperTopic">;
  readonly " $fragmentType": "contentTopicLinkSuperTopic";
};
export type contentTopicLinkSuperTopic$key = {
  readonly " $data"?: contentTopicLinkSuperTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentTopicLinkSuperTopic">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "contentTopicLinkSuperTopic"
};

(node as any).hash = "dfab68d7187e373fe85b6065dd6fc445";

export default node;
