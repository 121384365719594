/**
 * @generated SignedSource<<b98e307de5b262af8f7b85bea1de7e61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type videoDurationOverlayVideo$data = {
  readonly duration: string | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "videoDurationOverlayVideo";
};
export type videoDurationOverlayVideo$key = {
  readonly " $data"?: videoDurationOverlayVideo$data;
  readonly " $fragmentSpreads": FragmentRefs<"videoDurationOverlayVideo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "videoDurationOverlayVideo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SectionSegment",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
};

(node as any).hash = "66fcef8cf20ea79dd7fcb2dce777f2d2";

export default node;
