import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

const AuthorTextStyle = css`
  display: block;

  color: #000000;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18px;
`;

export const AuthorEntityLink = styled(EntityLink)`
  ${AuthorTextStyle};
`;

export const AuthorText = styled.div`
  ${AuthorTextStyle};
`;

export const Authors = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    display: inline-block;

    margin-inline: 2px;
  }
`;

export const AndSeparator = styled.span``;

export const CommasSeparator = styled.span``;
