import type { FunctionComponent } from "react";

import type { IconSize } from "scmp-app/components/video/video-duration-overlay";

import PlayIcon from "./play.svg";
import PodcastIcon from "./podcast.svg";
import { Container, Duration, DurationContainer, Icon } from "./styles";

type Props = {
  className?: string;
  duration: string;
  hasPodcastSection?: boolean;
  iconSize?: IconSize;
};

export const HomepageVideoDurationOverlay: FunctionComponent<Props> = ({
  className,
  duration,
  hasPodcastSection = false,
  iconSize = "small",
}) => (
  <Container className={className}>
    <Icon $iconSize={iconSize}>{hasPodcastSection ? <PodcastIcon /> : <PlayIcon />}</Icon>
    {!hasPodcastSection && (
      <DurationContainer>
        <Duration>{duration}</Duration>
      </DurationContainer>
    )}
  </Container>
);
