import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { contentPrintHeadlineContent$key } from "scmp-app/queries/__generated__/contentPrintHeadlineContent.graphql";

import { Container, StyledHeadline, StyledMessage } from "./styles";

type Props = {
  className?: string;
  reference: contentPrintHeadlineContent$key;
};

export const ContentPrintHeadline: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const { printHeadline } = useFragment(
    graphql`
      fragment contentPrintHeadlineContent on Content {
        printHeadline
      }
    `,
    reference_,
  );

  return printHeadline ? (
    <Container className={className}>
      <StyledMessage>Appeared in print as: </StyledMessage>
      <StyledHeadline>{printHeadline}</StyledHeadline>
    </Container>
  ) : null;
};

ContentPrintHeadline.displayName = "ContentPrintHeadline";
